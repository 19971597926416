input {
	width: 100%;
	border: 1px solid var(--color-input-border);
	padding: 1rem 2rem;
	border-radius: 0.9rem;
	font-family: Inter;
	font-size: 1rem;
	font-weight: 400;
}

input:active,
input:focus {
	outline: none;
}

input:placeholder {
	color: var(--color-input-placeholder);
}
