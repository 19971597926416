.r4{
    display: flex;
}
.r6{
    margin-left: 90px;
    margin-top: 100px;
}
.r7{
    margin-left: -10px;
    margin-top: 100px;
}