@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
	--font-family: 'Inter', sans-serif;

	--gradient-text: linear-gradient(270.11deg, #4d72da 0.08%, #6399f9 94.04%);
	--gradient-bg: linear-gradient(89.83deg, #f8fcff 1.11%, #f3faff 99.88%);

	--color-heading: #1c4b74;
	--color-navlinks: #5c5c5c;
	--color-text: #878899;
	--color-input-border: #00000029;
	--color-input-placeholder: #00000033;

	--color-warning-bg: rgba(255, 205, 68, 0.651);
	--color-terminate-bg: rgba(255, 68, 68, 0.651);
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
