
.bgimg{
    /* background-image: url(../../../assets/images/studash/bgimg.png);
    background-repeat: no-repeat;
    background-size: contain; */
    background-image: url(../../../assets/images/studash/pytbg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

.bgimg2{
    background-image: url(../../../assets/images/studash/bgimg2.png);
    background-repeat: no-repeat;
    background-size: cover;
}
@media (min-width: 375px) and (max-width: 799px) {
    .progress{
        display: block;
    }
}
@media screen and (max-width: 850px) {
    .n1{
      font-size: 1.2rem;
    }
  }