@media (min-width: 300px) and (max-width: 900px) {
    .Arrow {
      display: none;
    }
}


@media (min-width: 900px) and (max-width: 4000px) {
    .Arrow1 {
      display: none;
    }
}