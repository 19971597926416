.incubation{
    background-image: url(../../assets/images/studash/incubation/incubation.png);
    background-repeat: no-repeat;
    background-size: cover;
}

@media only screen and 
(max-width: 600px){
  .incubation{
    background-image: none;
  }
}

.incback{
    background-image: url(../../assets/images/studash/incubation/incubation2.png);
    background-repeat: no-repeat;
    background-size: cover;
}

@media only screen and (max-width: 600px) {
    .reverse {
      flex-direction: column-reverse;
    }
  }