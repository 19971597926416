.back-image{
    background-image: url('../images/NewFeed.png');
    
    background-repeat: no-repeat;
    background-size: cover;
    /* transform: rotate(180deg); */

    
}

/* .text-grid{
    transform: rotate(180deg);
} */



