.exam-container {
	display: grid;
	grid-template-columns: 1fr 3fr 2fr;
}

.exam-container .exam-details {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1.2rem;
	margin: 6rem 2rem;
	padding: 1rem 2rem;
	border: 1px solid var(--color-input-border);
	border-radius: 1rem;
	height: fit-content;
	max-width: 15rem;
}

.exam-container .exam-details .details {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 1rem;
	color: var(--color-links);
}

.exam-container .exam-details .details h4 {
	font-style: normal;
	font-weight: 400;
	word-wrap: wrap;
}

.form {
	display: flex;
	flex-direction: column;
	/* align-items: center;
	justify-content: center; */
	height: 100vh;
	background-color: var(--gradient-bg);
	gap: 1em;
	border: none;
}

.embedded-form .form h2 {
	display: flex;
	text-align: center;
	align-self: center;
}
.embedded-form{
	height: 80vh;
}


.timer {
	margin: 5rem 2rem 2rem 4rem;
	padding: 1rem 2rem;
	border: 1px solid var(--color-input-border);
	border-radius: 1rem;
	height: 80vh;
	/* max-width: 9rem; */
	/* display: flex; */
	/* align-items: center;
	justify-content: center; */
}

.responsive-message {
	display: none;
}

.disable {
	margin-left: 3rem;
	background-color: var(--color-warning-bg);
	z-index: 10;
	position: absolute;
	width: 75%;
	height: 100vh;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.terminate {
	background-color: var(--color-terminate-bg);
}

.blur {
	filter: blur(8px);
	-webkit-filter: blur(8px);
}

.hide {
	display: none;
}

@media only screen and (max-width: 800px) {
	h2 {
		font-size: 1.25rem;
		font-weight: 500;
	}

	.responsive-message {
		display: block;
	}

	.form-link,
	.form h2,
	.exam-details,
	.timer,
	.image-capture {
		display: none;
		visibility: hidden;
	}
}

.left-column .image-capture {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 5rem;
}

:fullscreen, ::backdrop {
    background-color: var(--gradient-bg);
}
