@media screen and (max-width: 850px) {
    .n1{
      margin-top: 5%;
    }
  }
  @media screen and (max-width: 850px) {
    .n2{
      margin-top: 4%;
    }
  }
  @media screen and (max-width: 850px) {
    .n3{
      display: inline;
    }
  }
  @media screen and (max-width: 850px) {
    .n4{
      width:'100%'
    }
  }