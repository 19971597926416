.background1{
    /* background-image: url(../../../assets/images/DashImage/Ladka2.png); */
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    background-color: #fff;
}

.center{
    display: block;
  margin-left: 50%;
  margin-right: 50%;
  width: 30%;
}

@media screen and (max-width: 850px) {
  .yo{
   margin-top: 6%;
   justify-content: center;
  }
}
@media screen and (max-width: 850px) {
  .searches{
  margin-top:'0px' !important
  }
}
@media screen and (max-width: 600px) {
  .ch{
  font-size: 0.5rem;
  }
}