@media screen and (max-width: 850px) {
  .mrsloginpage {
    padding: 4%;
    height: 100%
  }
}
@media screen and (max-width: 850px) {
  .ty {
    font-size: 30px;
  }
}
@media screen and (max-width: 850px) {
  .to {
    padding:'0px'
  }
}
@media screen and (max-width: 850px) {
  .tb{
    margin:'0 0 0 0' !important
  }
}