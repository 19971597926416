.rocket{
    background-image: url(../../assets/images/studash/incubation/rocket.png);
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: -7%;
    min-height: 154%;
}

.rocket1{
    background-image: url(../../assets/images/studash/incubation/rocket.png);
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: -18%;
    min-height: 154%;
}

.rocket2{
    background-image: url(../../assets/images/studash/incubation/rocket.png);
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: -19%;
    min-height: 154%;
}

.rocket3{
    background-image: url(../../assets/images/studash/incubation/rocket.png);
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: -36%;
    padding-top: 47%;
}

.rocket4{
    background-image: url(../../assets/images/studash/incubation/rocket.png);
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: -19%;
    min-height: 154%;
}