.bubble-image img{
    /* margin-left: 700px; */
    margin-top: 120px;
    animation: animate 1s alternate infinite;
    position: relative;
}

 .waving-image{
    margin-top: -560px;
    /* margin-left: -30px; */
} 

@keyframes animate{
    from{
        bottom: 0;
    }
    to{
        bottom: 60px;
    }
}