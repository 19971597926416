.container{
    width:267mm;
    height: 210mm;
    left:4mm;
 top:-6mm;
    /* display: grid; */
    justify-content: center;
    margin: 1%;
    padding: 1%;
    font-size: 60px;
    /* font-family: medieval,; */
    border: 10px;
    border-style:double;
    border-radius: 20px;
    border-color: gold;
  }
  
  .image{
    display: flex;
    justify-content: center;
  }
  
  .backgroundImage{
    
    position: absolute;
    height: 50%;
    width: 25%;
    left: 50%;
    top: 50%;
    translate: -50% -35%;
    z-index: -99;
    opacity: 20%;
  }
  
  .textarea{
    text-align: center;
    display: block;
    font-size: 30px;
  }
  
  .writearea{
    font-size: large;
    
  }
  
  .signature{
    padding-bottom: 10px;
  
    display: flex;
    /* justify-content: space-between; */
    font-size: 24px;
    width: 100%;
  }
  
  
  span{
    border-bottom-style: dashed;
    border: 5px;
  }