.ctabutton {
	background-color: #5880f0;
	color: #fff;
	font-family: Inter;
	font-size: 1rem;
	font-weight: 600;
	border: none;
	padding: 1.1rem 2.75rem;
	border-radius: 0.9rem;
}
