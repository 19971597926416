
.yourcourse {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
	padding: 3% 3% 0 3%;
	border-radius: 18px !important;
}
/* .yourcourse:hover {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
} */

.btn:hover {
    background-color: #008CBA;
    color: white;
  }
 