.user-register .logo img {
	width: 8rem;
}

.user-register {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2em;
	margin: 1.5rem;
}

.user-register .register-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2em;
}

.register-form .input-fields {
	max-width: 40vw;
	min-width: 30vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1.2em;
}

.input-fields input {
	padding: 0.9rem 1.8rem;
	font-size: 1rem;
}

.image-capture {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.image-capture button {
	color: #2f3138;
	background-color: rgb(44, 162, 216);
	font-family: Inter;
	font-size: 1rem;
	font-weight: 600;
	border: none;
	padding: 1rem 2.75rem;
	border-radius: 0.9rem;
	box-shadow: 10px 9px 40px 7px rgba(177, 202, 255, 0.21);
	-webkit-box-shadow: 10px 9px 40px 7px rgba(177, 202, 255, 0.21);
	-moz-box-shadow: 10px 9px 40px 7px rgba(177, 202, 255, 0.21);
}
